<template>
  <div class="layout">
      <Header ref="layoutHeader" />
      <router-view @initCart="initCart"></router-view>
      <Sidebar ref="layoutSidebar" />
      <Footer />
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
export default {
  components: {
    Header,
    Footer,
    Sidebar,
  },
  data: function () {
    return {};
  },
  created() {
    this.setLangFunc(); //设置英文语种
    this.hasLogin(); //检测是否有登录
    document.title = "XMVOGUE"
  },
  methods: {
    //判断是否登录操作
    hasLogin() {
      let webUserData = window.sessionStorage.getItem("webUser");
      let ts = localStorage.getItem("loginWeb");
      if (ts != null) {
        let userInfo = JSON.parse(ts);
        window.sessionStorage.setItem("webUser", JSON.stringify(userInfo));
        window.sessionStorage.setItem(
          "webToken",
          JSON.stringify(userInfo.token)
        );
      } else {
        this.$router.push("/login");
      }
    },
    //设置语言
    setLangFunc() {
      this.$i18n.locale = window.sessionStorage.getItem("lg");
      if (this.$i18n.locale === "en") {
        this.$store.commit("setLange", "en");
      }
    },
    //触发购物车事件
    initCart() {
      this.$refs.layoutHeader.initCart();
      // this.$refs.layoutSidebar.initCartData();
    },
  },
};
</script>
<style lang="scss" scoped>
.layout {
  width: 100%;
}
</style>
