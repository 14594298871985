<template>
  <div>

    <!-- 预售广告 -->
    <div class="pre_sale_div" v-show="showActivity" :style="pre_sale_div_stype" >
      <div><span><i class="el-icon-close" @click="showActivity = false"></i></span></div>
      <div @click="toPrePage">

        <p class="dayShow">{{ day }}</p>

        <span class="sptime cl1"> {{ hour }}</span>
        <span class="sptime cl2"> {{ minute }}</span>
        <span class="sptime cl3"> {{ seconds }}</span>
      </div>
    </div>

    <div class="Sidebar" style="display: none;">
      <div class="Sidebar_box" style="margin-top: 10px; font-size: 30px; color: white"
           @click="$router.push('/web/shoppingCart')">
        <div style="cursor: pointer">
          <el-tooltip effect="light" content="购物车" placement="bottom">
            <div>
              <el-badge :value="CountList" class="item">
                <i class="el-icon-shopping-cart-2"></i>
              </el-badge>
            </div>
          </el-tooltip>
        </div>
      </div>

      <!-- <div class="Sidebar_box" style="margin-top: 10px; font-size: 25px; color: white"
  @click="dialogSearchVisible = true">
  <div style="cursor: pointer">
    <el-tooltip effect="light" content="商品查询" placement="bottom">
      <div>
        <i class="el-icon-search"></i>
      </div>
    </el-tooltip>
  </div>
</div> -->

      <div class="Sidebar_box" style="margin-top: 10px; font-size: 30px; color: white"
           @click="$router.push('/web/orderCenter/bookingOrderList')">
        <div style="cursor: pointer">
          <el-tooltip effect="light" content="预售商品订单" placement="bottom">
            <div>
              <i class="el-icon-timer"></i>
            </div>
          </el-tooltip>
        </div>
      </div>

      <div class="Sidebar_box" style="margin-top: 10px; font-size: 30px; color: white" @click="handleBackToTop">
        <div style="cursor: pointer">
          <el-tooltip effect="light" content="回到顶部" placement="bottom">
            <div>
              <i class="el-icon-caret-top"></i>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

    <!-- <div class="add_dialog">
  <el-dialog title="商品查询" :visible.sync="dialogSearchVisible" width="500px">
    <div>
      <div class="addAdmin">
        <div class="addAdmin_select">
          <el-input v-model="new_seal_no" placeholder="请在此输入商品名称或商品编码" />
        </div>
        <div>
          <el-row>
            <el-button icon="el-icon-search"
              style="background: #218da0; color: white; font-size: 12px"></el-button>
          </el-row>
        </div>
      </div>
    </div>
  </el-dialog>
</div> -->

    <runging v-if="json.showDiv" :json="json" @closeView="closeView"></runging>
  </div>

</template>
<script>
import { SsCart } from "@/api/carts/carts.js";
import runging from "./rungirl";

export default {
  components: {
    runging,
  },
  data() {
    return {
      //runing girl
      json: {
        src: require("@/assets/images/presell/11/02.png"),
        // src: "",
        url: "/#/web/presellProduct",
        left: 0,
        top: 0,
        directionX: 0,
        directionY: 0,
        distance: null,
        interval: null,
        target: null,
        zIndex: null,
        width: null,
        height: null,
        noHoverPause: null, //鼠标经过时不停止，为true时不停止
        cursor: null,
        showDiv: false, //显示飘来飘去的效果
      },
      pre_sale_div_stype:{
        "position":"absolute",
        "z-index": "99",
        "top": "30%",
        "right": "15px",
        "height": "512px",
        "width": "225px",
        "background": ''
      },
      dialogSearchVisible: false,
      new_seal_no: "",
      CountList: 0,
      day: 1,
      hour: "", //倒计时  小时
      minute: "", // 倒计时 分钟
      seconds: "", //倒计时 秒
      interval: "",
      countdownTime: 3000,
      showActivity: false, //显示右边的效果
      showImgTime: '2023-4-30 23:00:00', //超过时间时间显示
      endTime: '2023-06-10 23:59:59',
      //showImgTime: 1653235200, //超过时间时间显示
    };
  },
  created() {
    // this.initCartData();
  },
  mounted() {
    // this.setTimer();
    // this.setTimeData(this.countdownTime * 60 * 1000);
    //this.setTimeData(this.countdownTime);
    this.getConfigData() //预售配置
    this.handleCountDown()

  },
  methods: {
    //关闭奔跑的巫女
    closeView() {
      this.json.showDiv = false;
    },

    /* 获取所有的预售配置数据 */
    getConfigData(){
        this.$axios.get("/w1/presellConfig/getConfig").then((res) => {
            let data = res.data.data
            // 设置值
            for (const key in data) {
                let key2 = data[key].config_key
                if (key2 ==="end_time") {
                  this.endTime = data[key].config_value
                }
                if (key2 ==="start_time") {
                  this.showImgTime = data[key].config_value
                }
                if (key2 ==="float_icon_img") {
                  this.json.src = data[key].config_value
                }
                if (key2 ==="count_down_img"){
                  this.pre_sale_div_stype.background ='url('+data[key].config_value+') no-repeat'
                }
            }            
        }).then(()=>{
          // 设置预售
          this.getTime()
        });
    },

    getTime() {
      let endTime = +new Date(this.endTime)/1000;
      let nowTime = (Date.now() / 1000).toFixed(0);

      const showTime = +new Date(this.showImgTime) /1000

      if (nowTime > showTime && nowTime < endTime){
         this.json.showDiv = true;
         this.showActivity = true;
      }

      let tc = endTime - nowTime;

      let oneDay = 3600 * 24;
      let tDay = parseInt(tc / oneDay); //算出剩余天数
      this.day = tDay;
       //算出剩余分钟
      this.countdownTime = ((tc - (tDay * 3600)) / 60).toFixed(0);

    },
    toPrePage() {
      this.$router.push("/web/presellProduct");
    },
    handleBackToTop() {
      document.body.scrollTop = 0;
    },
    //初始化购物车数量和价格
    initCartData() {
      let webUser = window.sessionStorage.getItem("webUser");
      let webUserJson = JSON.parse(webUser);
      if (webUserJson.user_info.id > 0) {
        SsCart(webUserJson.user_info.id)
          .then((res) => {
            let resData = res.data.data;
            this.CountList = resData.CountList;
          })
          .catch(() => {
            this.$message.error("Failed to get shopping cart data");
          });
      } else {
        this.$message.error("userinfo ERROR");
      }
    },
    handleCountDown(){
      const interval = setInterval(() => {
        let diffTime = (+new Date(this.endTime)/1000 - Date.now()/1000).toFixed(0)
        this.day = parseInt(diffTime/86400).toString().padStart(2,'0')
        this.hour = parseInt(diffTime / 60 / 60 % 24).toString().padStart(2,'0')
        this.minute = parseInt(diffTime / 60 % 60).toString().padStart(2,'0');
        this.seconds = parseInt(diffTime % 60).toString().padStart(2,'0')
        if (diffTime <= 0){
          clearInterval(interval)
        }
      },1000)
    }
  },
};
</script>
<style lang="scss" scoped>
.Sidebar {
  width: 40px;
  height: 190px;
  position: fixed;
  right: 100px;
  bottom: 100px;

  &_box {
    width: 40px;
    height: 40px;
    background: #218da0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.addAdmin {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &_select {
    width: 240px;
    flex: 1;
  }
}

/* update presell div  */
// .pre_sale_div {
//   position: absolute;
//   z-index: 99;
//   top: 30%;
//   right: 15px;
//   height: 512px;
//   width: 225px;
//   background: url("~@/assets/images/presell/11/01.png") no-repeat;
// }

//显示天
.dayShow {
  margin: 130px 0 0 30px;
  color: #ff9002;
  font-size: 80px;
  font-weight: 800;
}
//显示天跟时间的距离
.sptime {
  font-size: 26px;
  // color: rgb(223 66 72);
  color: #ff9002;
  // margin-top: 26px;
  margin-top: 1px;
}
//显示间距
.cl1 {
  // margin-left: 40px;
  margin-left: 43px;
}
.cl2 {
  margin-left: 32px;
}
.cl3 {
  margin-left: 30px;
}

//runging girl
#thediv {
  z-index: 100;
  position: absolute;
  top: 43px;
  left: 2px;
  height: 184px;
  width: 280px;
}
</style>
